/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search-mute': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M9.844 10.925a6.099 6.099 0 01-8.318-.782A6.106 6.106 0 015.911.003a6.1 6.1 0 016.265 5.53A6.106 6.106 0 0110.92 9.85l3.537 3.527a.765.765 0 11-1.081 1.082l-3.524-3.533h-.008zm-3.737-.244a4.574 4.574 0 004.576-4.578 4.582 4.582 0 00-2.825-4.23 4.574 4.574 0 00-6.327 4.23 4.58 4.58 0 004.576 4.578z" _fill="#B0B0B6"/>'
  }
})
